/**
 * Created by osirvent on 06/09/2016.
 */
angular
    .module('annexaApp')
    .factory('RegFactory',['$q', '$http','AnnexaFormlyFactory','$rootScope', 'TableFilter', 'DTColumnBuilder', '$filter', 'RegModals', 'RestService', 'HelperService', 'Language', 'ErrorFactory', 'DccumentsFactory', '$sce', 'AnnexaModalFactory', 'AnnexaPermissionsFactory', 'SignLocalFactory', 'CommonService', 'GlobalDataFactory', '$state', '$timeout', 'DialogsFactory', 'CacheFactory', '$interval', function($q, $http, AnnexaFormlyFactory, $rootScope, TableFilter, DTColumnBuilder, $filter, RegModals, RestService, HelperService, Language, ErrorFactory, DccumentsFactory, $sce, AnnexaModalFactory, AnnexaPermissionsFactory, SignLocalFactory, CommonService, GlobalDataFactory, $state, $timeout, DialogsFactory, CacheFactory, $interval) {
        var factory = {};

        //region Globals

        factory.regEntry = {};
        factory.regEntryTypes = [
            { id: 'INPUT', language1: $filter('translate')('global.literals.input'), language2: $filter('translate')('global.literals.input'), language3: $filter('translate')('global.literals.input') },
            { id: 'OUTPUT', language1: $filter('translate')('global.literals.output'), language2: $filter('translate')('global.literals.output'), language3: $filter('translate')('global.literals.output') }
        ];
        factory.previousRegisterEntryId = undefined;
        factory.nextRegisterEntryId = undefined;

        //endregion

        //region Router
        factory.familyProcedures = [];
        
        factory.getReqisterEntryUpdateAccessDiligences = function(id) {
        	 var promises = [];
             promises.push($http({url: './api/reg/' + id + '/update_access_diligences', method: 'GET'}));
             return $q.all(promises).then(function(data) {
                 factory.regEntry = JSOG.decode(data[0].data);
                 if(!factory.regEntry){
                 	$state.go('annexa.reg.output');
                 }
             }).catch(function (error) {
            	 factory.regEntry = {};
                 console.error(error);
             });
        }

        factory.getRegisterEntry = function(id) {
        	var promises = [];
            promises.push($http({url: './api/reg/' + id, method: 'GET'}));
            return $q.all(promises).then(function(data) {
                factory.regEntry = JSOG.decode(data[0].data);
                if(!factory.regEntry){
                	$state.go('annexa.reg.input');
                }
            }).catch(function (error) {
           	 	factory.regEntry = {};
                console.error(error);
            });
        }
        
        factory.getReqisterEntryByCsv = function(csv) {
        	var promises = [];
            promises.push($http({url: './api/reg/csv/' + csv, method: 'GET'}));
            return $q.all(promises).then(function(data) {
                factory.regEntry = JSOG.decode(data[0].data);
                if(!factory.regEntry){
                	$state.go('annexa.reg.input');
                }
            }).catch(function (error) {
           	 	factory.regEntry = {};
                console.error(error);
            });
        }
        
        factory.getReqisterEntryByCsvAndType = function(csv, type) {
        	var promises = [];
            promises.push($http({url: './api/reg/csv/type/' + csv+'/'+type, method: 'GET'}));
            return $q.all(promises).then(function(data) {
                factory.regEntry = JSOG.decode(data[0].data);
                if(!factory.regEntry){
                	$state.go('annexa.reg.input');
                }
            }).catch(function (error) {
           	 	factory.regEntry = {};
                console.error(error);
            });
        }

		factory.getReqisterEntryByNumber = function(number) {
        	var promises = [];
            promises.push($http({url: './api/reg/byNumber/' + number, method: 'GET'}));
            return $q.all(promises).then(function(data) {
                factory.regEntry = JSOG.decode(data[0].data);
                if(!factory.regEntry){
                	$state.go('annexa.dashboard');
                }
            }).catch(function (error) {
           	 	factory.regEntry = {};
                console.error(error);
            });
        }
        
        factory.getReqisterEntryByNumberAndType = function(number, type) {
        	var promises = [];
            promises.push($http({url: './api/reg/byNumber/type/' + number+'/'+type, method: 'GET'}));
            return $q.all(promises).then(function(data) {
                factory.regEntry = JSOG.decode(data[0].data);
                if(!factory.regEntry){
                	$state.go('annexa.dashboard');
                }
            }).catch(function (error) {
           	 	factory.regEntry = {};
                console.error(error);
            });
        }
        //endregion

        //region RegisterEntry

        factory.createRegisterEntry = function(registerEntry) {
            var deferred = $q.defer();

            $http({
                url: './api/reg',
                method: 'POST',
                data: JSOG.encode(registerEntry)
            }).then(function(data) {
                if(data.status == 200) { 
                	if(data && data.headers && data.headers('warningMessage')){
                		DialogsFactory.notify('DIALOGS_COMPLETE_GENERATE_STAMP_KO');
                	}
                	deferred.resolve(JSOG.decode(data.data));
                } else {
                    deferred.reject(data.status);
                }
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.createSupportingDocument = function(id, entryType, returnType) {
            var deferred = $q.defer();

            var translations = {
                "third_relation_interested": $filter('translate')('global.literals.interested'),
                "third_relation_representative": $filter('translate')('global.literals.representative'),
                "supportingDoc": entryType == 'INPUT' ? $filter('translate')('global.literals.supportingDoc') : $filter('translate')('global.literals.evidenceDoc'),
                "yes": $filter('translate')('global.literals.yes'),
                "no": $filter('translate')('global.literals.no'),
                "languageColumn": Language.getActiveColumn()
            }

            var httpConfig = {
                url: './api/reg/' + id + '/supporting_document',
                method: 'POST',
                data: translations,
                cache: false
            }

            if(returnType == 'FILE') {
                httpConfig.responseType = 'arraybuffer';
            }

            if(returnType == 'DOCUMENT') {
                httpConfig.url += '/document'
            }

            $http(httpConfig).then(function(response) {
                if(returnType == 'FILE') {
                    var file = new Blob([response.data], {type: 'application/pdf'});

                    if (typeof window.navigator.msSaveBlob !== 'undefined') {
                        deferred.resolve(file);
                    } else {
                        var linkElement = document.createElement('a');
                        try {
                            var url = window.URL.createObjectURL(file);
                            linkElement.setAttribute('href', url);
                            linkElement.setAttribute('target', '_blank');
                            deferred.resolve(linkElement);
                        } catch (e) {
                            console.error(e.message);
                        }
                    }
                } else if(returnType == 'DOCUMENT') {
                    deferred.resolve(JSOG.decode(response.data));
                }
            }).catch(function (error) {
                deferred.reject(error);
            })

            return deferred.promise;
        }
        
        factory.generateDocumentSicresFile = function(id, params) {
        	var deferred = $q.defer();
        	
            var fileContentsForDownload = function(headers, data) {
                var retDownload = {
                    file: undefined,
                    fileName: undefined,
                    linkElement: undefined,
                    clickEvent: undefined
                }

                var contentType = headers['content-type'];
                retDownload.file = new Blob([data], { type: contentType });
                retDownload.fileName = headers['content-disposition'].replace('attachment; filename="','').replace('"', '');

                try {
                    if (window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(retDownload.file, retDownload.fileName);
                    } else {
                        retDownload.linkElement = document.createElement('a');
                        var url = window.URL.createObjectURL(retDownload.file);
                        retDownload.linkElement.setAttribute('href', url);
                        retDownload.linkElement.setAttribute("download", retDownload.fileName);
                        retDownload.clickEvent = new MouseEvent("click", {
                            "view": window,
                            "bubbles": true,
                            "cancelable": false
                        });
                        retDownload.linkElement.dispatchEvent(retDownload.clickEvent);
                    }
                } catch(e) {
                }
            }
        	
            $http({
                url: './api/reg/' + id + '/generateDocumentSicres',
                method: 'POST',
                responseType: 'arraybuffer',
                data: JSOG.encode(params)
            }).then(function (data) {                
            	if(data.status == 200) {
                   fileContentsForDownload(data.headers(), data.data);
                   deferred.resolve(true);
                } else {
                	deferred.reject(error);
                }
            }).catch(function(error) {
                deferred.reject(error);
            })

            return deferred.promise;
        }
        
        factory.generateDocumentSicres = function(id, params) {
        	var deferred = $q.defer();
        	
            $http({
                url: './api/reg/' + id + '/generateDocumentSicres/document',
                method: 'POST',
                data: JSOG.encode(params)
            }).then(function (data) {                
            	deferred.resolve(JSOG.decode(data.data));
            }).catch(function(error) {
                deferred.reject(error);
            })

            return deferred.promise;
        }
        
        factory.getFieldsSicresForm = function(id, params) {
        	var deferred = $q.defer();
        	
        	$http({
                url: './api/reg/getFieldsSicresForm',
                method: 'GET'
            }).then(function (data) {                
            	deferred.resolve(JSOG.decode(data.data));
            }).catch(function(error) {
                deferred.reject(error);
            })
        	
        	return deferred.promise;
        }

        factory.getPermissionsUserEntryType = function(entryType, edit) {
            var editDays = 0;
            var permissions = [];

            var getPermissions = function (type) {
                var perms = [];

                switch (type) {
                    case 'INPUT':
                        if (AnnexaPermissionsFactory.havePermission('edit_input_entry_register')) {
                            perms.push('edit_input_entry_register');
                        }
                        if (AnnexaPermissionsFactory.havePermission('new_input_entry_register')) {
                            perms.push('new_input_entry_register');
                        }
                        break;
                    case 'OUTPUT':
                        if (AnnexaPermissionsFactory.havePermission('edit_output_entry_register')) {
                            perms.push('edit_output_entry_register');
                        }
                        if (AnnexaPermissionsFactory.havePermission('new_output_entry_register')) {
                            perms.push('new_output_entry_register');
                        }
                        break;
                }

                return perms;
            }

            if(edit && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.reg_edit_days && $rootScope.app.configuration.reg_edit_days.value) {
                editDays = $rootScope.app.configuration.reg_edit_days.value;
            }

            if(editDays == 0 || (editDays > 0 && HelperService.diffDateDays(factory.regEntry.createdDate, new Date() <= editDays))) {
                permissions = getPermissions(entryType);
            }

            return permissions;
        };

        factory.newRegisterEntryThird = function(id, newRegisterEntryThird) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/' + id + '/thirds',
                method: 'POST',
                data: JSOG.encode(newRegisterEntryThird)
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data))
            }).catch(function(error) {
                deferred.reject(error);
            })

            return deferred.promise;
        }
        
        factory.updateRegisterEntryThird = function(id, registerEntryThird) {
            var deferred = $q.defer();

            $http({
                url: './api/register_entry_third/',
                method: 'PUT',
                data: JSOG.encode(registerEntryThird)
            }).then(function (data) {
                deferred.resolve(JSOG.decode(data.data))
            }).catch(function(error) {
                deferred.reject(error);
            })

            return deferred.promise;
        }

        factory.deleteRegisterEntryThird = function(id, removedIds) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/' + id + '/thirds/' + CommonService.getParameterList(removedIds),
                method: 'DELETE'
            }).then(function (data) {
                deferred.resolve(data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.deleteRegisterEntryThirdWithThird = function(id, removedIds) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/' + id + '/thirds_with_third/' + CommonService.getParameterList(removedIds),
                method: 'DELETE'
            }).then(function (data) {
                deferred.resolve(data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        
        factory.setPrincipalRegisterEntryThird = function(id, thirdId) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/' + id + '/thirds/set_principal/' + thirdId,
                method: 'PUT'
            }).then(function (data) {
                deferred.resolve(data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }
        
        factory.newRegisterEntryDocument = function(id, newRegisterEntryDocuments) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/' + id + '/documents',
                method: 'POST',
                data: JSOG.encode(newRegisterEntryDocuments)
            }).then(function(data) {
                deferred.resolve(JSOG.decode(data.data))
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.deleteRegisterEntryDocument = function(id, removedId) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/' + id + '/documents/' + removedId,
                method: 'DELETE'
            }).then(function (data) {
                deferred.resolve(data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.newRegisterEntryDossier = function(id, dossierId) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/' + id + '/dossiers/' + dossierId,
                method: 'POST',
            }).then(function(data) {
                deferred.resolve(JSOG.decode(data.data))
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.deleteRegisterEntryDossier = function(id, dossierId) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/' + id + '/dossiers/' + dossierId,
                method: 'DELETE'
            }).then(function (data) {
                deferred.resolve(data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.deleteRegisterEntryDiligence = function(id, removedId) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/' + id + '/diligences/' + removedId,
                method: 'DELETE'
            }).then(function (data) {
                deferred.resolve(data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.newRegisterEntryDiligence = function(id, diligence) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/' + id + '/diligences',
                method: 'POST',
                data: JSOG.encode(diligence)
            }).then(function(data) {
                deferred.resolve(JSOG.decode(data.data))
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.newRegisterEntryRelatedRegisterEntry = function(id, registerEntryId) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/' + id + '/related_register_entries/' + registerEntryId,
                method: 'POST',
            }).then(function(data) {
                deferred.resolve(JSOG.decode(data.data))
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.deleteRegisterEntryRelatedRegisterEntry = function(id, removedId) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/' + id + '/related_register_entries/' + removedId,
                method: 'DELETE'
            }).then(function (data) {
                deferred.resolve(data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.newRegisterEntryTask = function(id, taskId) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/' + id + '/tasks/' + taskId,
                method: 'POST',
            }).then(function(data) {
                deferred.resolve(JSOG.decode(data.data))
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.deleteRegisterEntryTask = function(id, removedId) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/' + id + '/tasks/' + removedId,
                method: 'DELETE'
            }).then(function (data) {
                deferred.resolve(data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.newRegisterEntryAddress = function(id, address) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/' + id + '/address',
                method: 'POST',
                data: JSOG.encode(address)
            }).then(function(data) {
                deferred.resolve(JSOG.decode(data.data))
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.deleteRegisterEntryAddress = function(id, removedId) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/' + id + '/address/' + removedId,
                method: 'DELETE'
            }).then(function (data) {
                deferred.resolve(data);
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.duplicateRegisterEntryDiligence = function(id, newRegisterEntry, diligenceId) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/' + id + '/duplicate/' + diligenceId,
                method: 'POST',
                data: JSOG.encode(newRegisterEntry)
            }).then(function(data) {
            	if(data.status == 200) { 
                	if(data && data.headers && data.headers('warningMessage')){
                		DialogsFactory.notify('DIALOGS_DUPLICATE_REGISTER_ENTRY_KO');
                	}
                	deferred.resolve(JSOG.decode(data.data));
                } else {
                    deferred.reject(data.status);
                }
            }).catch(function(error) {
                deferred.reject(error);
            });
            
            return deferred.promise;
        }

        //endregion

        //region DuplicateRegister
        
        factory.duplicateRegisterModal = function(submit, title, registerEntryOffices) {
        	             
             // formulario de crear nueva diligencia
             var newModal = {
                 title: title ? title : 'global.reg.literals.duplicateRegister',
                 size: '',
                 fieldsDefinition: [
                     { type: 'field', id: 'regOffice', fieldType: 'select', required: true, label: 'global.literals.register_office', data: registerEntryOffices},
                     { type: 'field', id: 'profile', fieldType: 'select', required: true, label: 'global.literals.profile', data: $linq(GlobalDataFactory.allProfiles).where("x => x.expiryDate == null").toArray()},
                     { type: 'field', id: 'section', fieldType: 'select', required: true, label: 'global.literals.section', data: GlobalDataFactory.sections}
                     /*{ type: 'predefined', predefined: 'profile', id: 'profile', data: 'loggedUser', origin: 'RegisterEntryInput', required: true},
                     { type: 'predefined', predefined: 'section', id: 'section', data: 'loggedUser', origin: 'RegisterEntryInput', required: true}*/
                 ],
                 alerts: [],
                 submitModal: submit
             }

             return newModal;
        }
        
        //endregion
        
        // region RegisterEntryDiligence

        factory.newDiligenceModal = function(content, submit, title, withoutDiligenceType, defaults, entryOffice) {
            var getDiligenceTypes = function () {
                var diligenceTypes = GlobalDataFactory.registerDiligenceTypes;

                if($linq(content).count("x => x.active && x.diligenceType.canStartDossier") > 0 && entryOffice && !entryOffice.registerEntryInputInitDossierDiligenceTypeMultiple) {
                    diligenceTypes = $linq(GlobalDataFactory.registerDiligenceTypes).where("x => !x.canStartDossier").toArray();
                }

                return diligenceTypes;
            }
            
            var profilesRegisterOffice = [];
            if(entryOffice && entryOffice.profiles){
            	profilesRegisterOffice = $linq(entryOffice.profiles).select("x => x.profile").toArray();
            }
           
            // formulario de crear nueva diligencia
            var newModal = {
                title: title ? title : 'global.reg.modal.new.dilig',
                size: '',
                fieldsDefinition: [
                    { type: 'field', id: 'diligenceType', fieldType: 'select', data: getDiligenceTypes(), required: true, label: 'global.literals.type', remove: withoutDiligenceType, model: defaults && defaults.diligenceType ? defaults.diligenceType.id : undefined },
                    { type: 'field', id: 'diligenceProfiles', fieldType: 'component', componentType: 'annexa-typeahead-fieldset-profiles',
                        data: {
                            label: 'global.literals.profiles',
                            placeholder: 'global.reg.literals.profileOrUserName',
                            onlyOneProfile: withoutDiligenceType,
                            rowClass: 'm-b-xs row',
                            labelStrong: true,
                            profilesOk: profilesRegisterOffice
                        },
                        controller: ['$scope', function($scope) {
                            $scope.options.data.contents = $scope.model.diligenceProfiles;
                        }],
                        defaultValue: []
                    },
                    { type: 'field', id: 'comment', fieldType: 'textarea', required: false, label: 'global.literals.comments' }
                ],
                alerts: [],
                submitModal: submit
            }

            return newModal;
        }

        factory.responsibleUser = function(registerEntryDiligence) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/register_entry_diligence/' + registerEntryDiligence.id + '/responsible_user',
                method: 'PUT',
                data: registerEntryDiligence.responsibleUser ? undefined : { id: $rootScope.LoggedUser.id }
            }).then(function(data) {
                if(data.status == 200) {
                    deferred.resolve('OK');
                } else {
                    deferred.reject(data.status);
                }
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.updateRegisterEntryDiligenceState = function(registerEntryDiligence, diligenceState, comment) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/register_entry_diligence/' + registerEntryDiligence.id + '/state/' + diligenceState,
                method: 'PUT',
                data: comment
            }).then(function(data) {
                if(data.status == 200) {
                    deferred.resolve(data);
                } else {
                    deferred.reject(data.status);
                }
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.updateRegisterEntryDiligenceActive = function(registerEntryDiligence, active) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/register_entry_diligence/' + registerEntryDiligence.id + '/active',
                method: 'PUT',
                data: active
            }).then(function(data) {
                if(data.status == 200) {
                    deferred.resolve(data);
                } else {
                    deferred.reject(data.status);
                }
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.sendToDiligence = function(registerEntryDiligence, newRegisterEntryDiligence) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/register_entry_diligence/' + registerEntryDiligence.id + '/send_to',
                method: 'PUT',
                data: JSOG.encode(newRegisterEntryDiligence)
            }).then(function(data) {
                if(data.status == 200) {
                    deferred.resolve(JSOG.decode(data.data));
                } else {
                    deferred.reject(data.status);
                }
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.sendTodiligenceModalOpen = function(previousDiligence, registerEntry, redirectTo) {
            var submitSendTo = function() {
                this.form.$setSubmitted();

                if(!this.model.diligenceProfiles || this.model.diligenceProfiles.length == 0) {
                    sendToDiligenceModal.alerts.push({ msg: 'global.errors.profiles_required'});
                }

                if(this.form.$valid && this.model.diligenceProfiles && this.model.diligenceProfiles.length != 0) {
                    var newDiligence = {
                        diligenceType: previousDiligence.diligenceType,
                        diligenceProfile: this.model.diligenceProfiles[0].profile ? { id: this.model.diligenceProfiles[0].profile.id } : undefined,
                        responsibleUser: this.model.diligenceProfiles[0].responsible ? { id: this.model.diligenceProfiles[0].responsible.id } : undefined,
                        registerEntryDiligenceComments: this.model.comment ? [{ comments: this.model.comment }] : [],
                        registerEntry: { id: registerEntry.id, profile: { id: registerEntry.profile.id } }
                    }

                    factory.sendToDiligence(previousDiligence, newDiligence)
                        .then(function(data) {
                            sendToDiligenceModal.close();

                            if(redirectTo) {
                                $state.go(redirectTo);
                            } else {
                                $rootScope.$broadcast('registerEntryDiligenceSendedTo', { previousDiligence: previousDiligence, newDiligence: data });
                            }
                        }).catch(function(error) {
                            console.error(error);
                            sendToDiligenceModal.alerts.push({ msg: ErrorFactory.getErrorMessage('registerDiligence', 'sendTo', error) });
                    });
                }
            }
            
            var entryOffice;
            if(registerEntry && registerEntry.registerEntryOffice){
            	entryOffice = registerEntry.registerEntryOffice;
            }

            var sendToDiligenceModal = factory.newDiligenceModal([], submitSendTo, 'global.reg.modal.sendDiligenceTo', true, { diligenceType: previousDiligence.diligenceType }, entryOffice);
            AnnexaFormlyFactory.showAnnexaFormModal('modalSendTo', sendToDiligenceModal);
        }

        factory.attachToDossier = function (registerEntryDiligence,dossierTransactionId, receiverIds, documentIds) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/register_entry_diligence/' + registerEntryDiligence.id + '/attach_dossier_transaction/' + dossierTransactionId + '/' + CommonService.getParameterList(receiverIds) + '/' + CommonService.getParameterList(documentIds),
                method: 'PUT'
            }).then(function(data) {
                if(data.status == 200) {
                    deferred.resolve(JSOG.decode(data.data));
                } else {
                    deferred.reject(data.status);
                }
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        // endregion

        //region RegisterEntryDiligenceComment

        factory.createRegisterEntryDiligenceCommentModal = function(diligence) {
            var newModal = {
                title: 'global.literals.newComment',
                size: '',
                fieldsDefinition: [
                    { type: 'field', id: 'registerEntryDiligenceComments', fieldType: 'component', componentType: 'annexa-register-entry-diligence-comments',
                        data: {
                            registerEntryDiligence: diligence,
                            addComments: true
                        }
                    }
                ],
                alerts: [],
                submitModal: function() {
                    this.close();
                },
                hideSubmit: true,
                closeLabel: 'global.literals.close'
            }

            AnnexaFormlyFactory.showAnnexaFormModal('modalNewRegisterEntryDiligenceComment', newModal);
        };

        factory.newRegisterEntryDiligenceComment = function(comment) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/register_entry_diligence_comment',
                method: 'POST',
                data: JSOG.encode(comment)
            }).then(function (data) {
                deferred.resolve(data.data)
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        //endregion

        //region Modal

        factory.newRegisterEntryModal = function(entryType, thirds, documents, dossiers, submit, fromDossier, tramClassification) {
            var newModal = {
                id: 'modalNewRegisterEntry',
                title: 'global.literals.new_register_modal_title',
                size: 'modal-lg',
                icon: '',
                isEdit: false,
                isModal: true,
                redirectToList: false,
                entryType: entryType,
                preloadBoxes: { thirds: thirds, documents: documents, dossiers: dossiers },
                fromDossier: fromDossier,
                tramClassification: tramClassification,
                alerts: [],
                submitModal: function () {
                    $timeout(function () {
                        angular.element('#annexaForm-new-registerentry').trigger('click');
                    });
                }
            }

            AnnexaFormlyFactory.showAnnexaFormModalObject('modalNewRegisterEntry', newModal);
        }

        //endregion

        //region RegisterEntryClassification

        factory.findFamiliesByRegisterClassification = function(registerClassification, profile) {
            var callUrl = './api/reg/register_entry_classification';

            if(registerClassification && registerClassification.id) {
                callUrl += '/' + registerClassification.id;
            }

            callUrl += '/families';

            if(profile && profile.id) {
                callUrl += '/' + profile.id;
            }

            var httpPromise = $http({
                url: callUrl,
                method: 'GET'
            }).success(function(response) {
                response.content = JSOG.decode(response.content);
                return response;
            }).error(function(error) {
                console.log(error);
                return error;
            });

            return httpPromise;
        }

        factory.getFamilyProcedures = function(){
        	var deferred = $q.defer();
        	if(factory.familyProcedures && factory.familyProcedures.length > 0){
            	deferred.resolve(factory.familyProcedures);
        	}else{
        		if(CacheFactory.get('globalDataCache') && CacheFactory.get('globalDataCache').get('familyProceduresAndProcedures')){
            		var cache = CacheFactory.get('globalDataCache').get('familyProceduresAndProcedures');
            		if(cache && cache.length > 0){
            			factory.familyProcedures = JSOG.decode(angular.fromJson(cache));
            			deferred.resolve(factory.familyProcedures);
            		}
            	}
            	if(!factory.familyProcedures || factory.familyProcedures.length == 0){
            		RestService.findAll('FamilyProcedure', 'initDossier').then(function(data) {
            			 try {
                         	CacheFactory.get("globalDataCache").put('familyProceduresAndProcedures',data.data);
                         } catch (error) {
                 			console.error(error);
                 		}
            			factory.familyProcedures = JSOG.decode(data.data);
            			deferred.resolve(factory.familyProcedures);
            		}).catch(function(error) {
            			deferred.reject([]);
            		});
            	}
        	}
        	return deferred.promise;
        }
        
        factory.getDossierTransactionByDossierId = function(ids) {
            var httpPromise = $http({
                url: './api/tram/dossierTransactions/dossiersId/'+CommonService.getParameterList(ids),
                method: 'GET'
            }).then(function(data) {
            	if(data && data.data){
            		return JSOG.decode(data.data);
            	}else{
            		return [];
            	}
            }).catch(function(error) {
                console.log(error);
                return error;
            });

            return httpPromise;
        }
        //endregion
        factory.getRelatedThirds = function(regId){
            var deferrend = $q.defer();
            $http({
                method: 'GET',
                url: './api/reg/' + regId + '/getRelatedThirds',
            }).success(function(data, status) {
                deferrend.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        };
        
        factory.getRegisterEntryDiligenceAudit = function(diligenceId){
            var deferrend = $q.defer();
            $http({
                method: 'GET',
                url: './api/reg/register_entry_diligence/' + diligenceId + '/audit',
            }).success(function(data, status) {
                deferrend.resolve(JSOG.decode(data));
            }).error(function(msg,code) {
                deferrend.reject(msg);
            });
            return deferrend.promise;
        };

        factory.getPreviousRegisterEntry = function(regId, entryType){
            return $http({
            		url: './api/reg/' + regId + '/getPreviousRegisterEntry/' + entryType, 
            		method: 'GET'
            	}).success(function(data) {
//            		console.log('previous registerEntryId: ', data);
	            	if(data){
	            		factory.previousRegisterEntryId = data;
	            		$rootScope.$broadcast('prevRegisterEntry', { prevRegisterEntry: data });
	            	} else {
	            		factory.previousRegisterEntryId = undefined;
	            		$rootScope.$broadcast('prevRegisterEntry', { prevRegisterEntry: undefined });
	            	}
	            }).error(function (error) {
	            	factory.previousRegisterEntryId = undefined;
	                console.error(error);
	            });
        };

        var fight = function(id, entryType) {
            if(angular.isDefined(stop)) {
                return;
            }
            stop = $interval(function() {
                if($rootScope.LoggedUser) {
                    factory.getNextRegisterEntry(id, entryType);
                }
            }, 60000);
        }

        var stopFight = function() {
            if(angular.isDefined(stop)) {
                $interval.cancel(stop);
                stop = undefined;
            }
        }

        factory.runCheckNextRegisterEntry = function(id, entryType) {
            stopFight();
            fight(id, entryType);
        }

        factory.getNextRegisterEntry = function(regId, entryType){
            return $http({
            		url: './api/reg/' + regId + '/getNextRegisterEntry/' + entryType, 
            		method: 'GET', headers: { noLoading: true }
            	}).success(function(data) {
//		        	console.log('next registerEntryId: ', data);
		        	if(data){
		        		stopFight();
		        		factory.nextRegisterEntryId = data;
		        		$rootScope.$broadcast('nextRegisterEntry', { nextRegisterEntry: data });
		        	} else {
		        		factory.nextRegisterEntryId = undefined;
		        		$rootScope.$broadcast('nextRegisterEntry', { nextRegisterEntry: undefined });
		        	}
		        }).error(function (error) {
		        	factory.nextRegisterEntryId = undefined;
		            console.error(error);
		        });
        };

        return factory;
    }]);