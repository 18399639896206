/**
 * Created by osirvent on 06/09/2016.
 */
angular
    .module('annexaApp')
    .factory('NotificationFactory',['$q', '$http', '$filter', 'AnnexaModalFactory','globalModals','$rootScope', 'ErrorFactory', 'AnnexaFormlyFactory', 'Upload', 'DccumentsFactory', 'ThirdFactory', 'SecFactory','Language', 'CommonService', 'RestService', 'GlobalDataFactory', 'DialogsFactory', function($q, $http, $filter, AnnexaModalFactory, globalModals, $rootScope, ErrorFactory, AnnexaFormlyFactory, Upload, DccumentsFactory, ThirdFactory, SecFactory, Language, CommonService, RestService, GlobalDataFactory, DialogsFactory) {

        var factory = {};

        //region Globals

        factory.notificationGlobalStatus = [
            {id: 'PENDING', name: $filter('translate')('PENDING'), class: 'text-warn', icon: 'circle', style: 'text-warn'},
            {id: 'SEND', name: $filter('translate')('global.literals.SEND'), class: 'text-info', icon: 'circle', style: 'text-info'},
            {id: 'COMPLETE', name: $filter('translate')('global.literals.COMPLETE'), class: 'text-success', icon: 'circle', style: 'text-success'},
            {id: 'ERROR', name: $filter('translate')('global.literals.ERROR'), class: 'text-danger', icon: 'circle', style: 'text-danger'}
        ];
        //endregion

        //region General

        factory.getNotificationAddress = function (third, dossier) {
            var defaultAddress = $linq(third.addresses).firstOrDefault(undefined, "x => x.default");
            var allConsent = $linq(third.consents).firstOrDefault(undefined, "x => x.consentType == 'ALL'");

            if(dossier && third.consents) {
                var dossierConsent = $linq(third.consents).singleOrDefault(undefined, "x => x.consentType == 'DOSSIER' && ((x.dossier && x.dossier.id == " + dossier.id + ") || (x.dossierId == " + dossier.id + "))");
                var procedureConsent = $linq(third.consents).singleOrDefault(undefined, "x => x.consentType == 'PROCEDURE' && x.procedure && x.procedure.id == " + dossier.procedure.id);
                var familyProcedureConsent = dossier.procedure.family ? $linq(third.consents).singleOrDefault(undefined, "x => x.consentType == 'FAMILY_PROCEDURE' && x.familyProcedure && x.familyProcedure.id == " + dossier.procedure.family.id) : undefined;


                if (dossierConsent && dossierConsent.thirdAddress && !dossierConsent.thirdAddress.deleted) {
                    return dossierConsent.thirdAddress;
                } else if (procedureConsent && procedureConsent.thirdAddress && !procedureConsent.thirdAddress.deleted) {
                    return procedureConsent.thirdAddress;
                } else if (familyProcedureConsent && familyProcedureConsent.thirdAddress && !familyProcedureConsent.thirdAddress.deleted) {
                    return familyProcedureConsent.thirdAddress;
                }
            }

            return allConsent ? ((allConsent.thirdAddress.deleted)? defaultAddress : allConsent.thirdAddress) : defaultAddress;
        }

        factory.notificationsByThirds = function(thirdsAddress, notificationTypes, extract, thirdId, createNotificationFunction, pluginParams) {
            var deferred = $q.defer();
            var idPostal = $linq(notificationTypes).where("x => x.addressType == 'POSTAL'").selectMany("x => x.notificationTypes", "(n, a) => n").singleOrDefault(undefined, "x => x.selected");
            var idEmail = $linq(notificationTypes).where("x => x.addressType == 'TELEMATIC_EMAIL'").selectMany("x => x.notificationTypes", "(n, a) => n").singleOrDefault(undefined, "x => x.selected");
            var idPostalCode = $linq(notificationTypes).where("x => x.addressType == 'POSTAL_CODE'").selectMany("x => x.notificationTypes", "(n, a) => n").singleOrDefault(undefined, "x => x.selected");
            var idPhone = $linq(notificationTypes).where("x => x.addressType == 'TELEMATIC_MOBILEPHONE'").selectMany("x => x.notificationTypes", "(n, a) => n").singleOrDefault(undefined, "x => x.selected");

            var addressTypes = {};

            if (idPostal) {
                addressTypes.POSTAL = idPostal.id;
            }

            if (idEmail) {
                addressTypes.TELEMATIC_EMAIL = idEmail.id;
            }

            if (idPostalCode) {
                addressTypes.POSTAL_CODE = idPostalCode.id;
            }

            if (idPhone) {
                addressTypes.TELEMATIC_MOBILEPHONE = idPhone.id;
            }

            createNotificationFunction(thirdsAddress, addressTypes, extract, deferred, pluginParams);

            return deferred.promise;
        }

        //endregion

        //region New Modal

        factory.notificationNewModal = function(thirds, documents, dossier, registerEntry, addresses, docSelected, thirdsIdSelected, archiveClassifications) {
        	var documentsToAddAux = [];
			var documentsToAdd = [];
        	var thirdsEnabled = undefined;
        	var documentTransaction = [];
        	if (dossier && dossier.dossierTransactions) {
        		documentsToAdd = $linq($linq(dossier.dossierTransactions).selectMany("x => x.documents").toArray()).distinctBy("x => x.document.id").where("x => x.document.docStatus != 'NEW' && x.document.type.includeNotification == true && x.document.physical == false").select("x => x.document").toArray();
        		_.forEach(dossier.dossierTransactions, function(dosTran){
        			var docs = $linq(documentsToAdd).intersect($linq(dosTran.documents).select("x => x.document").toArray(), "(x, y) => x.id == y.id").toArray();
        			if(docs.length > 0) {
        				documentTransaction.push({
    						value: dosTran.id,
    						label: dosTran.language1,
    						documents: docs
    					})
        			}
        		});
				thirdsEnabled = {};
				if(thirds){
					_.forEach(thirds, function(third){
						var docs = $linq($linq(dossier.dossierTransactions).selectMany("x => x.documents").toArray()).distinctBy("x => x.document.id").where("x => x.document.type.includeNotification == true && x.dossierThird && x.dossierThird.id == "+third.id).select("x => x.document.id").toArray();
						thirdsEnabled[third.id] = {enabled:true, docs:docs};
					});
				}else if(dossier && dossier.thirds){
					_.forEach(dossier.thirds, function(third){
						var docs = $linq($linq(dossier.dossierTransactions).selectMany("x => x.documents").toArray()).distinctBy("x => x.document.id").where("x => x.document.type.includeNotification == true && x.dossierThird && x.dossierThird.id == "+third.id).select("x => x.document.id").toArray();
						thirdsEnabled[third.id] = {enabled:true, docs:docs};
					});
				}
        	}
        	if(documentsToAdd && documentsToAdd.length == 0 && documents && documents.length > 0){
        		if(documents[0].document){
        			documentsToAdd = $linq(documents).where(function(x){
        				if(x && x.document && x.document.type && x.document.type.id && !x.document.physical){
        					var type = $linq(GlobalDataFactory.allDocumentTypes).firstOrDefault({includeNotification:false}, "x => x.id == "+x.document.type.id)
        					if(type.includeNotification){
        						return true;
        					}else{
        						return false;
        					}
        				}else {
        					return false;
        				}
        			}).toArray();
					if(documentsToAdd && documentsToAdd.length > 0){
						if(thirds){
							documentsToAddAux = angular.copy(documentsToAdd);
							var numDocs = $linq(documentsToAddAux).count("x => x.document && x.document.type.includeNotification == true && x.registerEntryThird && x.registerEntryThird.id");
							if(numDocs != undefined && numDocs > 0){
								thirdsEnabled = {};
								_.forEach(thirds, function(third){
									var docs = $linq(documentsToAddAux).where("x => x.document && x.document.type.includeNotification == true && x.registerEntryThird && x.registerEntryThird.id == "+third.id).select("x => x.document.id").toArray();
									thirdsEnabled[third.id] = {enabled:true, docs:docs};
								});
							}
						}
						documentsToAdd = $linq(documentsToAdd).where("x => x.document.docStatus != 'NEW' && x.document.type.includeNotification == true").select("x => x.document").toArray();
					}
        		}else{
        			documentsToAdd = $linq(documents).where(function(x){
        				if(x && x.type && x.type.id && !x.physical && x.docStatus != 'NEW'){
        					var type = $linq(GlobalDataFactory.allDocumentTypes).firstOrDefault({includeNotification:false}, "x => x.id == "+x.type.id)
        					if(type.includeNotification){
        						return true;
        					}else{
        						return false;
        					}
        				}else {
        					return false;
        				}
        			}).toArray();
					documentsToAddAux = angular.copy(documentsToAdd);
        		}
        	}
        	
        	var documentsIdSelected = [];
        	if (documents && dossier) {
        		$linq(documents).foreach(
        				function (doc) { 
        					if(doc.type.includeNotification && !doc.physical){
        						documentsIdSelected.push(doc.id); 
        					}
        				}
        			);
        	}
        	if(docSelected && docSelected.length > 0){
        		_.forEach(docSelected, function(docId){
        			if(!_.contains(documentsIdSelected, docId)){
        				documentsIdSelected.push(docId);
        			}
        		});
        	}
        	var newModal = {
                title: 'global.literals.new_notification_title',
                size: 'modal-lg',
                fieldsDefinition: [],
                dossierTransactionId: undefined,
                documentTransaction: documentTransaction,
            	alerts: [],
                submitModal: function() {
                }
            };
			 newModal.fieldsDefinition.push({ type: 'field', id: 'documents', fieldType: 'component', componentType: 'annexa-select-documents',
                data: {
                    title: 'global.literals.documents',
                    documents: documentsToAdd,
                    documentsIdSelected: documentsIdSelected.length > 0 ? documentsIdSelected : undefined
                }
            });
            if(addresses){
            	newModal.fieldsDefinition.push({ type: 'field', id: 'thirds', fieldType: 'component', componentType: 'annexa-select-attendee',
                    data: {
                        title: 'global.literals.receivers',
                        thirds: thirds ? thirds : [],
                        addresses: addresses,
                        thirdsIdSelected: ((thirdsIdSelected && thirdsIdSelected.length > 0) ? thirdsIdSelected : undefined),
                        removeAddressesWithoutSelectedNotificationType: true
                    }
                });
        	}else{
				if(thirds){
					_.forEach(thirds, function(third){
						third.selected = false;
					});
				}else if(dossier && dossier.thirds){
					_.forEach(dossier.thirds, function(third){
						third.selected = false;
					});
				}
        		newModal.fieldsDefinition.push({ type: 'field', id: 'thirds', fieldType: 'component', componentType: 'annexa-select-thirds',
                    data: {
                        title: 'global.literals.receivers',
                        thirds: thirds ? thirds : (dossier && dossier.thirds ? dossier.thirds : []),
                        dossier: dossier,
						removeAddressesWithoutSelectedNotificationType: true,
                        registerEntry: registerEntry,
						thirdsEnabled: thirdsEnabled
                    },
					controller: function ($scope, $filter) {
						$scope.$on('annexa-select-documents-selectedDocuments', function(event, args) {
							if(documentsToAdd && thirdsEnabled){
								var docs = [];
								if(dossier && dossier.dossierTransactions){
									docs = $linq($linq(dossier.dossierTransactions).selectMany("x => x.documents").toArray()).distinctBy("x => x.document.id").where("x => x.document.type.includeNotification == true && !x.dossierThird").select("x => x.document.id").toArray();
								}else{
									if(documentsToAddAux && documentsToAddAux.length > 0 && documentsToAddAux[0].document){
										docs = $linq(documentsToAddAux).where("x => x.document && x.document.type.includeNotification == true && !x.registerEntryThird").select("x => x.document.id").toArray();
									}else if(documentsToAddAux && documentsToAddAux.length > 0){
										docs = $linq(documentsToAddAux).select("x => x.id").toArray();
									}
								}
								var docsAux = $linq(documentsToAdd).where("x => x.selected == true").select("x => x.id").toArray();
								var inters = $linq(docs).intersect(docsAux).toArray();
								if(inters && inters.length > 0){
									for(prop in thirdsEnabled){
										if(thirdsEnabled[prop]){
											thirdsEnabled[prop].enabled = true;
											if(docsAux && docsAux.length > 0){
												if(thirdsEnabled[prop].docs && thirdsEnabled[prop].docs.length > 0){
													var docsThird = $linq(docsAux).intersect(thirdsEnabled[prop].docs).toArray();
													if(docsThird && docsThird.length > 0){
														thirdsEnabled[prop].enabled = false;
														if($scope.options && $scope.options.data && $scope.options.data.thirds){
															var th = $linq($scope.options.data.thirds).firstOrDefault(undefined, "x => x.id =="+prop);
															if(th){
																th.selected = true;
															}
														}
													}
												}
											}
										}
									}
								}else{	
									for(prop in thirdsEnabled){
										if(thirdsEnabled[prop]){
											if(docsAux && docsAux.length > 0){
												if(thirdsEnabled[prop].docs && thirdsEnabled[prop].docs.length > 0){
													var docsThird = $linq(docsAux).intersect(thirdsEnabled[prop].docs).toArray();
													if(docsThird && docsThird.length > 0){
														thirdsEnabled[prop].enabled = false;
														if($scope.options && $scope.options.data && $scope.options.data.thirds){
															var th = $linq($scope.options.data.thirds).firstOrDefault(undefined, "x => x.id =="+prop);
															if(th){
																th.selected = true;
															}
														}
													}else{
														thirdsEnabled[prop].enabled = false;
														if($scope.options && $scope.options.data && $scope.options.data.thirds){
															var th = $linq($scope.options.data.thirds).firstOrDefault(undefined, "x => x.id =="+prop);
															if(th){
																th.selected = false;
															}
														}
													}
												}else{
													thirdsEnabled[prop].enabled = false;
													if($scope.options && $scope.options.data && $scope.options.data.thirds){
															var th = $linq($scope.options.data.thirds).firstOrDefault(undefined, "x => x.id =="+prop);
															if(th){
																th.selected = false;
															}
														}
												}
											}else{
												thirdsEnabled[prop].enabled = true;
											}
										}
									}	
								}
							}
        				});
                    }            
                });
        	}
            
            if(documentTransaction.length > 1) {
            	newModal.fieldsDefinition.push({type: 'field', id: 'transaction', fieldType: 'radio_checkbox', isRadio: true, 
	        		data: documentTransaction,
	        		hideExpression: function ( $viewValue, $modelValue, $scope) {
	        			var documentsField = $linq($scope.fields).singleOrDefault(void 0, "x => x.key == 'documents'");
        				var selectedDocs = $linq(documentsField.data.documents).where("x => x.selected").toArray();
        				if(selectedDocs && selectedDocs.length > 0){
        					var listTransactionsField = $linq($scope.fields).singleOrDefault(void 0, "x => x.key == 'listTransactions'");
        					var transactionId = [];
        					_.forEach(listTransactionsField.templateOptions.options, function(dosTran){
        	        			var docs = $linq(selectedDocs).intersect(dosTran.documents, "(x, y) => x.id == y.id").toArray();
        	        			if(docs.length > 0) {
        	        				transactionId.push(dosTran);
        	        			}
        	        		});
        					if(transactionId.length > 1){
        						var transactionField = $linq($scope.fields).singleOrDefault(void 0, "x => x.key == 'transaction'");
        						transactionField.templateOptions.options = $linq(listTransactionsField.templateOptions.options).intersect(transactionId, "(x, y) => x.value == y.value").toArray();
        						return false;
        					} else {
        						$scope.model.transaction = undefined;
        						return true;
        					}
        				} else {
        					$scope.model.transaction = undefined;
        					return true;
        				}
        			},
	        		colClass: 'col-sm-12', required: true, label: 'global.literals.transactionEvidence' , labelProp: 'label'});
            	newModal.fieldsDefinition.push({ type: 'field', id: 'listTransactions', fieldType: 'select_multiple', colClass: 'daughter-label-strong', labelProp: 'allName', data: documentTransaction, label: 'global.literals.transactionEvidence',
            		hideExpression: function ( $viewValue, $modelValue, $scope) { return true;} });
            }
            newModal.fieldsDefinition.push({ type: 'predefined', predefined: 'profile', id: 'profile', data: 'loggedUser', origin: 'RegisterEntryInput', colClass: ' ', required: true});
            newModal.fieldsDefinition.push({ type: 'predefined', predefined: 'section', id: 'section', data: 'loggedUser', colClass: ' ', required: true});
            
            if(archiveClassifications) {
            	var requiredArchiveClassification = false;
                if($rootScope.app.configuration.required_archive_classification_register_output) {
                	requiredArchiveClassification = $rootScope.app.configuration.required_archive_classification_register_output.value
                }
            	
            	newModal.fieldsDefinition.push({ type: 'field', id: 'archiveClassification', fieldType: 'select-tree', colClass: '', data: archiveClassifications, required: requiredArchiveClassification, label: 'global.literals.classificationBox', model: {}  });
            }
            var showTramClassification = false;
            var requiredTramClassification = false;
            if($rootScope.app.configuration.tram_classification_register_output) {
        		requiredTramClassification = $rootScope.app.configuration.tram_classification_register_output.required;
        		showTramClassification = $rootScope.app.configuration.tram_classification_register_output.show;
        	}
            if(showTramClassification) {
            	var defaultTramClassification = undefined;
            	if(dossier && dossier.tramClassification) {
            		defaultTramClassification = dossier.tramClassification;
            	}
            	newModal.fieldsDefinition.push({ type: 'field', id: 'tramClassification', fieldType: 'select', data: GlobalDataFactory.tramClassifications, required: requiredTramClassification, label: 'global.literals.tramClassification', defaultValue: defaultTramClassification });
            }
            
        	var defaultExpirationDays = $rootScope.app.configuration.notification_expiration_days.value;
        	var disableExpirationDays = false;
        	if(dossier && dossier.procedure){
        		if (dossier.procedure.expirationDaysNotification)
        			defaultExpirationDays = dossier.procedure.expirationDaysNotification;

        		if(dossier.procedure.allowUserChangeExpirationDaysNotification != undefined)
        			disableExpirationDays = !dossier.procedure.allowUserChangeExpirationDaysNotification;
        	}
        	newModal.fieldsDefinition.push({ type: 'field', id: 'expirationDaysNotification', fieldType: 'number', required: true, label: 'global.literals.expirationDays', defaultValue: defaultExpirationDays, disabled: disableExpirationDays});
            newModal.fieldsDefinition.push({ type: 'field', id: 'extract', fieldType: 'textarea', required: true, label: 'global.literals.reason'});
            
            if (dossier || addresses) {
	            if(!registerEntry && $rootScope.app.configuration.multiple_register_office && $rootScope.app.configuration.multiple_register_office.active && GlobalDataFactory.registerEntryOffices.length > 1) {
	            	//Si el usuario puede registrar en mas de una oficina de registro
	                newModal.fieldsDefinition.unshift({ type: 'field', id: 'registerEntryOffice', fieldType: 'select', data: GlobalDataFactory.registerEntryOffices, required: true, label: 'global.literals.register_office' });
	            } else if (!registerEntry && $rootScope.app.configuration.multiple_register_office && $rootScope.app.configuration.multiple_register_office.active && GlobalDataFactory.registerEntryOffices.length == 1) {
	            	//Si el usuario solo puede registrar en una solo oficina de registro se le asignara esta directamente
	            	newModal.fieldsDefinition.unshift({ type: 'field', id: 'registerEntryOffice', fieldType: 'hidden', data: GlobalDataFactory.registerEntryOffices, defaultValue: GlobalDataFactory.registerEntryOffices[0], required: true});
	            }
            }

            return newModal;
        }

        factory.getNewNotifications = function(form, modal, parentIsModal) {
            form.form.$setSubmitted();

            var getDossier = function () {
                var fieldThirds = $linq(form.fields).singleOrDefault(undefined, "x => x.key == 'thirds'");

                if(fieldThirds) {
                    return fieldThirds.data.dossier ? { id: fieldThirds.data.dossier.id } : undefined;
                }

                return undefined;
            }
            
            var getProfile = function() {
                var profileSelected = $linq(form.fields).singleOrDefault(undefined, "x => x.key == 'profile'");

                if(profileSelected) {
                    return profileSelected.formControl.$viewValue.id ? { id: profileSelected.formControl.$viewValue.id } : undefined;
                }

                return undefined;
            }

            var getSection = function () {
                var sectionSelected = $linq(form.fields).singleOrDefault(void 0, "x => x.key == 'section'");

                if(sectionSelected) {
                    return sectionSelected.formControl.$viewValue.id ? { id: sectionSelected.formControl.$viewValue.id } : undefined;
                }

                return undefined;
            }
            
            var getArchiveClassification = function () {
                if(form && form.model && form.model.archiveClassification && form.model.archiveClassification.$selected
                		&& form.model.archiveClassification.$selected.id) {
                    return { id: form.model.archiveClassification.$selected.id };
                }

                return undefined;
            }
            
            var getTramClassification = function () {
            	var tramClassificationSelected = $linq(form.fields).singleOrDefault(void 0, "x => x.key == 'tramClassification'");

                if(tramClassificationSelected && tramClassificationSelected.formControl && tramClassificationSelected.formControl.$viewValue) {
                    return tramClassificationSelected.formControl.$viewValue.id ? { id: tramClassificationSelected.formControl.$viewValue.id } : undefined;
                }

                return undefined;
            }
            
            var getRegisterEntryOffice = function () {
                var registerEntryOfficeSelected = $linq(form.fields).singleOrDefault(void 0, "x => x.key == 'registerEntryOffice'");

                if(registerEntryOfficeSelected) {
                    return registerEntryOfficeSelected.formControl.$viewValue.id ? { id: registerEntryOfficeSelected.formControl.$viewValue.id } : undefined;
                }

                return undefined;
            }

            var getRegisterEntry = function () {
                var fieldThirds = $linq(form.fields).singleOrDefault(undefined, "x => x.key == 'thirds'");
                if(fieldThirds) {
                   return fieldThirds.data.registerEntry ? { id: fieldThirds.data.registerEntry.id } : { profile: getProfile(), section: getSection(), registerEntryOffice: getRegisterEntryOffice(), archiveClassification: getArchiveClassification(), tramClassification: getTramClassification()};
                }
                return { profile: getProfile(), section: getSection(), registerEntryOffice: getRegisterEntryOffice(), archiveClassification: getArchiveClassification(), tramClassification: getTramClassification()};
            }

            var getAddresses = function() {
                var fieldThirds = $linq(form.fields).firstOrDefault(undefined, "x => x.key == 'thirds'");

                if(fieldThirds) {
                    var selectedThirds = $linq(fieldThirds.data.thirds).where("x => x.selected").toArray();

                    if(selectedThirds && selectedThirds.length > 0) {
                        var addressesSelected = [];

                        _.forEach(selectedThirds, function(item) {
                            var selectedAddress = $linq(item.addressesOrig).firstOrDefault(undefined, "x => x.selected");

                            if(selectedAddress) {
                                addressesSelected.push({ address: selectedAddress, third: ((item.third)?item.third:{id:item.id}) });
                            }
                        });

                        if(addressesSelected.length == selectedThirds.length) {
                            return addressesSelected;
                        }
                    }
                }

                return undefined;
            }

            var getDocuments = function() {
                var fieldDocuments = $linq(form.fields).singleOrDefault(undefined, "x => x.key == 'documents'");

                if(fieldDocuments) {
                    return $linq(fieldDocuments.data.documents).where("x => x.selected")
                        .select(function(x) {
                            return x;
                        }).toArray()
                }

                return undefined;
            }

            var getExtract = function () {
                var fieldExtract = $linq(form.fields).singleOrDefault(void 0, "x => x.key == 'extract'");

                if(fieldExtract && fieldExtract.value) {
                    return fieldExtract.value();
                }

                return undefined;
            }
            
            var getDossierTransactionId = function (documents) {
            	if(form.model.transaction) {
            		return { id: form.model.transaction };
            	} else if(modal.documentTransaction && modal.documentTransaction.length > 1) {
            		var idTrans = undefined;
            		_.forEach(modal.documentTransaction, function(docTrans) {
                        if($linq(docTrans.documents).contains(documents[0], "(x, y) => x.id == y.id")) {
                        	idTrans = { id: docTrans.value };
                        }
                    });
            		return idTrans;
            	} else if(modal.documentTransaction && modal.documentTransaction.length == 1) {
            		return { id: modal.documentTransaction[0].value };
            	} else if(modal.dossierTransactionId) {
            		return { id: modal.dossierTransactionId };
            	}
            	return undefined;
            }

            var extractCorrectLength = function(extract, addresses){
            	if(extract && addresses){
            		var minLengthArray = $linq(addresses).where("x=> x.address && x.address.selectedNotificationType && x.address.selectedNotificationType.limitSizeExtractNotifications && x.address.selectedNotificationType.limitSizeExtractNotifications > 0").select("x => x.address.selectedNotificationType.limitSizeExtractNotifications").toArray();
            		if(minLengthArray && minLengthArray.length > 0){
            			return $linq(minLengthArray).min();
            		}
            	}
            	return 0;
            }
            var addresses = getAddresses();
            var documents = getDocuments();
            var extract = getExtract();
            var extractMinlength = extractCorrectLength(((form && form.model && form.model.extract)?form.model.extract:undefined), addresses);
            if(!addresses) {
            	var fieldThirds = $linq(form.fields).firstOrDefault(undefined, "x => x.key == 'thirds'");
            	var selectedThirds = $linq(fieldThirds.data.thirds).where("x => x.selected").toArray();
            	
            	if(selectedThirds && selectedThirds.length > 0) {
            		modal.alerts.push({ msg: 'global.errors.channelNoValidSendNotifications' });
            	} else {
            		modal.alerts.push({ msg: 'global.errors.noDestinataris' });
            	}                
            } else if(!documents || documents.length == 0) {
                modal.alerts.push({ msg: 'global.errors.noDocuments' });
            } else if(form && form.model && form.model.extract && form && form.model && extractMinlength > 0 && form.model.extract.length > extractMinlength) {
                modal.alerts.push({ msg: ($filter('translate')('global.errors.maxLengthOfExtract')+' '+extractMinlength+' '+$filter('translate')('global.errors.actualLengthOfExtract')+ ' '+ form.model.extract.length+ '.') });
            } else {
                if(form.form.$valid) {
                    var newNotifications = [];

                    _.forEach(addresses, function (item) {
                        var status = undefined;
                        if(item && item.address && item.address.selectedNotificationType && item.address.selectedNotificationType.statuses && item.address.selectedNotificationType.statuses.length > 0){
                        	status = item.address.selectedNotificationType.statuses[0];
                        }
                        var valueAlertAddress = undefined;
                        if(item.address.selectedNotificationType.selectedAlertAddress && item.address.selectedNotificationType.selectedAlertAddress.id != 0) {
                        	var alertAddress = item.address.selectedNotificationType.selectedAlertAddress;
                        	if(alertAddress && alertAddress.addressType && alertAddress.addressType == 'POSTAL' && alertAddress.address) {
                        		valueAlertAddress = alertAddress.address['address'+Language.getActiveColumn()];
                        	} else if(alertAddress){
                        		valueAlertAddress = (alertAddress.phoneInternationalPrefix?alertAddress.phoneInternationalPrefix:'') + alertAddress.telematicValue;
                        	}
                        }
                    	delete item.address.selectedNotificationType.selectedAlertAddress;
                    	delete item.address.selectedNotificationType.alertAddresses;
                        newNotifications.push({
                            notificationType: item.address.selectedNotificationType,
                            globalStatus: 'PENDING',
                            registerEntry: getRegisterEntry(),
                            dossier: getDossier(),
                            documents: documents,
                            thirds: [{ 
                            	third: item.third, 
                            	address: ((item.address && item.address.selectedNotificationType && item.address.selectedNotificationType.addressType == 'OTHER')?undefined:item.address), 
                            	statuses: [{createdDate: new Date(), observations: extract, status: status}],
                            	telematicNumber: valueAlertAddress
                            }],
                            extract: form.model.extract,
                            createUser: { id: $rootScope.LoggedUser.id },
                            createdDate: new Date(),
                            dossierTransaction: getDossierTransactionId(documents),
                            expirationDays: form.model.expirationDaysNotification
                            //status: item.address.selectedNotificationType.statuses[0]
                        });
                    });
                    
                    var notificationsToValidate = $linq(newNotifications).where("x => x.notificationType.limitSizeDocumentsNotifications > 0").toArray();

                    var notiExceedNumberDocs = exceedsLimitNumberDocumentsNotification(newNotifications, documents.length);
                    var exceedsLimitInBytes = exceedsLimitSizeDocumentsNotification(notificationsToValidate); 
                    
                    if(notiExceedNumberDocs != null) {
                    	modal.alerts.push({ msg: $filter('translate')('global.errors.limitDocumentsNoti', { notificationType: notiExceedNumberDocs.notificationType.language1, maxLimitDocuments: notiExceedNumberDocs.notificationType.limitNumberDocumentsNotifications}) });
                    } else if(exceedsLimitInBytes > 0) {
                    	var limitInMB = parseFloat((exceedsLimitInBytes / 1000000).toFixed(2)); //Bytes a MegaBytes
                		DialogsFactory.confirm('DIALOGS_CONFIRMATION_MSG', $filter('translate')('DIALOGS_NOTIFICATION_MAX_DOCUMENT_SIZE_KO_1') + " (" + limitInMB + " MB) " + $filter('translate')('DIALOGS_NOTIFICATION_MAX_DOCUMENT_SIZE_KO_2')).then(function (data) {
                			$rootScope.$broadcast('created_multiple_notifications', { notifications: newNotifications, parentIsModal:((parentIsModal)?'isModal':'isNotModal') });
                        	modal.close();
                		}).catch(function(data) {
                            //Empty
                        });
                    } else {                    	
                    	$rootScope.$broadcast('created_multiple_notifications', { notifications: newNotifications, parentIsModal:((parentIsModal)?'isModal':'isNotModal') });
                    	modal.close();
                    }
                }
            }
        }
        
        var exceedsLimitSizeDocumentsNotification = function(notificationsToValidate) {
        	var res = parseInt(0);
        	
        	if (notificationsToValidate.length > 0) {
	        	for (var i=0; i < notificationsToValidate.length; i++) {
	        		var notification = notificationsToValidate[i];
	        		var totalDocumentsSize = parseInt(0);
	            	
	    			if (notification && 
	    					notification.notificationType && 
	    					notification.notificationType.limitSizeDocumentsNotifications) {
	    				
	    				$linq(notification.documents).foreach(
	        				function (doc) {
	        					if(doc.sizePDFDoc && doc.sizePDFDoc != ''){
	        						totalDocumentsSize = parseInt(totalDocumentsSize) + parseInt(doc.sizePDFDoc);
	        					}else if (doc.sizeDoc) {            				
	        						totalDocumentsSize = parseInt(totalDocumentsSize) + parseInt(doc.sizeDoc);
	                    		}
	        				}
	            		);
	    				
	    			}
	    			
	    			if (totalDocumentsSize > 0 && 
	    					notification && 
	            			totalDocumentsSize > notification.notificationType.limitSizeDocumentsNotifications) {
	    				//Al menos una notificacion supera el limite del tamanio de documentos
	    				res = notification.notificationType.limitSizeDocumentsNotifications; 
	    				break;
	    			}
	        	}
        	}
        	
			return res;
        }
        
        var exceedsLimitNumberDocumentsNotification = function(notificationsToValidate, totalDocuments) {
        	var noti;
        	var limitDocuments;
        	
        	if (notificationsToValidate.length > 0) {
	        	for (var i=0; i < notificationsToValidate.length; i++) {
	        		var notification = notificationsToValidate[i];
	            	
	    			if (notification &&
	    					notification.notificationType && 
	    					notification.notificationType.limitNumberDocumentsNotifications) {
	    				if(limitDocuments != null && limitDocuments > notification.notificationType.limitNumberDocumentsNotifications){
	    					limitDocuments = notification.notificationType.limitNumberDocumentsNotifications;
	    					noti = notification;
	    				} else if(limitDocuments == null) {
	    					limitDocuments = notification.notificationType.limitNumberDocumentsNotifications;
	    					noti = notification;
	    				}
	    			}
	        	}
        	}
        	
        	if(totalDocuments > limitDocuments) {
        		return noti;
        	}
        	
			return null;
        }

        //endregion

        //region View Modal

        factory.notificationViewModal = function(notification, origin) {
            var receivers = [];

            _.forEach(notification.thirds, function (item) {
                receivers.push({
                    id: item.third.id,
                    label: CommonService.getThirdCompleteName(item.third)
                })
            })

            var viewModal = {
                isEditable: true,
                inDivRow: true,
                hideSubmit: true,
                closeLabel: 'global.literals.close',
                title: 'global.literals.edit_notification_title',
                size: 'modal-lg',
                fieldsDefinition: [
                    { type: 'field', id: 'notificationType', fieldType: 'component', componentType: 'annexa-label-value', label: 'global.literals.type', className: 'col-lg-4',
                        data: {
                            value: notification.notificationType[Language.getActiveColumn()],
                            type: 'text',
                            rowClass: 'm-b-xs'
                        }
                    },
                    { type: 'field', id: 'extract', fieldType: 'component', componentType: 'annexa-label-value', label: 'global.literals.reason', className: 'col-lg-4',
                        data: {
                            value: notification.extract,
                            type: 'textarea',
                            rowClass: 'm-b-xs'
                        }
                    },
                    { type: 'field', id: 'expirationDays', fieldType: 'component', componentType: 'annexa-label-value', label: 'global.literals.expirationDays', className: 'col-lg-4',
                        data: {
                            value: notification.expirationDays,
                            type: 'text',
                            rowClass: 'm-b-xs'
                        }
                    },
                    { type: 'field', id: 'documents', fieldType: 'component', componentType: 'annexa-label-value', label: 'global.literals.documents', className: 'col-lg-12',
                        data: {
                            value: notification.documents,
                            type: 'documentList',
                            origin: 'NOTIFICATION'
                        }
                    },
                    { type: 'field', id: 'evidences', fieldType: 'component', componentType: 'annexa-notification-statuses-evidences', className: 'col-lg-12',
                        data: {
                            notification: notification,
                            canEdit: origin && origin == 'tram-view' ? false : true
                        }
                    }
                ],
                alerts: [],
                submitModal: function() {
                }
            }

            return viewModal;
        }

        factory.viewModal = function(notification) {
            var modal = angular.copy(globalModals.notificationModify);

            modal.data = notification;

            AnnexaModalFactory.showModal('modalEditNotification', modal);
        }

        //endregion

        //region NotificatonTypes Modal

        factory.newNotificationsByAddresses = function (addresses) {
            var newModal = {
                title: 'global.literals.createNewNotifications',
                size: '',
                icon: 'fa fa-check-square-o',
                fieldsDefinition: [
                    { type: 'field', id: 'addresses', fieldType: 'component', componentType: 'annexa-notification-addresses',
                        data: {
                            addresses: addresses,
                            notificationTypes: []
                        }
                    }
                ],
                alerts: [],
                submitModal: function() {
                	var notificationTypes = this.fields[0].data.notificationTypes;

                    $rootScope.$broadcast('selectedNotificationTypes', {
                        notificationTypes: notificationTypes,
                        modal: newModal,
                        thirdsAddresses: addresses
                    });
                }
            };

            AnnexaFormlyFactory.showAnnexaFormModal('modalNewNotification', newModal);
        }

        //endregion

        //region REST

        factory.createMultipleNotifications = function(notifications) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/notification/multiple',
                method: 'POST',
                data: JSOG.encode(notifications)
            }).then(function(data) {
                deferred.resolve(JSOG.decode(data.data));
            }).catch(function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.getEvidence = function(evidence) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/notification/evidence/' + CommonService.fixRepoIdForSendRest(evidence),
                method: 'GET',
            }).then(function(data) {
                deferred.resolve(data.data);
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        factory.createNotificationThirdStatus = function(notificationThirdStatus) {
            var deferred = $q.defer();

            $http({
                url: './api/reg/notification_third_status',
                method: 'POST',
                data: JSOG.encode(notificationThirdStatus)
            }).then(function(data) {
                deferred.resolve(data.data);
            }).catch(function(error) {
                deferred.reject(error);
            });

            return deferred.promise;
        }

        //endregion

        return factory;
    }]);