/**
 * Created by dpardell on 23/11/2015.
 */

angular
    .module('annexaApp')
    .controller('RegViewEntriesController',['$scope', 'RegFactory', '$state', '$stateParams', function ($scope, RegFactory, $state, $stateParams) {
    	if(RegFactory.regEntry && RegFactory.regEntry.entryType == 'OUTPUT'){
    		$state.go('annexa.reg.viewOutput', $stateParams);
        }else if(RegFactory.regEntry && RegFactory.regEntry.entryType == 'INPUT'){
        	$state.go('annexa.reg.viewInput', $stateParams);
        }else{
        	$state.go('annexa.dashboard');
        }
    }])
	.controller('RegViewEntriesByNumberController',['$scope', 'RegFactory', '$state', '$stateParams', function ($scope, RegFactory, $state, $stateParams) {
    	if(RegFactory.regEntry && RegFactory.regEntry.entryType == 'OUTPUT'){
    		$state.go('annexa.reg.viewByNumberOutput', $stateParams);
        }else if(RegFactory.regEntry && RegFactory.regEntry.entryType == 'INPUT'){
        	$state.go('annexa.reg.viewByNumberInput', $stateParams);
        }else{
        	$state.go('annexa.dashboard');
        }
    }]);
